export default [
  {
    value: 19,
    text: '19%',
  },
  {
    value: 7,
    text: '7%',
  },
  {
    value: 16,
    text: '16%',
  },
  {
    value: 5,
    text: '5%',
  },
  {
    value: 0,
    text: '0%',
  },
  {
    value: 21,
    text: '21%',
  },
  {
    value: 20,
    text: '20%',
  },
]
