export default {
  DNS_RECORD_TYPES: [
    'A',
    'AAAA',
    'AFSDB',
    'ALIAS',
    'CAA',
    'CERT',
    'CDNSKEY',
    'CDS',
    'CNAME',
    'DNSKEY',
    'DNAME',
    'DS',
    'HINFO',
    'KEY',
    'LOC',
    'MX',
    'NAPTR',
    'NSEC',
    'NSEC3',
    'NSEC3PARAM',
    'OPENPGPKEY',
    'PTR',
    'RP',
    'RRSIG',
    'SOA',
    'SPF',
    'SSHFP',
    'SRV',
    'TKEY',
    'TSIG',
    'TLSA',
    'SMIMEA',
    'TXT',
    'URI',
  ],
}
