import genders from './genders'
import roles from './roles'
import taxes from './taxes'
import tlds from './tlds'
import zones from './zones'

const intervals = [
  {
    value: 'days',
    text: 'days',
  },
  {
    value: 'weeks',
    text: 'weeks',
  },
  {
    value: 'months',
    text: 'months',
  },
  {
    value: 'years',
    text: 'years',
  },
]

const discountTypes = [
  {
    value: 'percent',
    text: 'percent',
  },
  {
    value: 'fixed',
    text: 'fixed',
  },
]

const shippingTypes = [
  {
    value: 'hide',
    text: 'Hide shippingdate',
  },
  {
    value: 'shippingdate',
    text: 'shippingdate',
  },
  {
    value: 'shippingperiod',
    text: 'shippingperiod',
  },
  {
    value: 'servicedate',
    text: 'servicedate',
  },
  {
    value: 'serviceperiod',
    text: 'serviceperiod',
  },
]

export { roles, tlds, taxes, genders, intervals, discountTypes, shippingTypes }

export const dnsRecordTypes = zones.DNS_RECORD_TYPES
